import { object, array, string, number } from 'yup';
import { lt } from 'yup-locales';
import { setLocale } from 'yup';

setLocale(lt);

export interface UserFormInterface {
  customer_email: string
  customer_parent_name: string,
  customer_name: string,
  customer_age: number,
  customer_phone_no: string,
  customer_address: string,
  day_selected: string,
  customer_guests: number,
  time_selected: string | null,
  invitation_type: string,
  discount_code: string,
  services_selected: string[],
  print_invites: string,
  city_uuid: string; // Added city_uuid



}

export const formSchema = object({
  customer_email: string().label("El. paštas").required().email(),
  customer_parent_name: string()
    .label("Vieno iš tėvų vardas")
    .required()
    .min(7)
    .max(40)
    .matches(/^[A-Za-ząĄčČęĘėĖįĮšŠųŲūŪžŽ]{3,}\s[A-Za-ząĄčČęĘėĖįĮšŠųŲūŪžŽ]{3,}$/, 'Prašome įvesti vardą ir pavardę, kiekvienas turi būti bent 3 raidžių ilgio, patikrinkite ar nepalikote tarpo po vardo.'),
  customer_name: string().label("Vaiko vardas").required().min(3).max(40).matches(/^[A-Za-ząĄčČęĘėĖįĮšŠųŲūŪžŽ]*$/, 'Vardas gali būti sudarytas tik iš raidžių, Pavardės rašyti nereikia, patikrinkite ar nepalikote tarpo po vardo.'),
  customer_age: number().label("Amžius").required().min(4).max(99),
  customer_guests: number().label("Svečių skaičius").required().min(1).max(24),
  customer_phone_no: string().label("Telefono numeris").required().matches(/^[0-9]{8}$/, { message: "Telefono nr. turi atitikti +37000000000 formatą" }),
  day_selected: string(),
  time_selected: string(),
  invitation_type: string().required().label("Pakvietimas"),
  discount_code: string(),
  print_invites: string().required().oneOf(["true", "false"]),
  services_selected: array().of(string().required().length(32)),
  customer_address: string().label("Adresas").required().min(4).max(100)
});